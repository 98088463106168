














































































































































import {Component, Vue} from "vue-property-decorator";
import {
  TrainMethod,
  AttachmentHostType, LecturerDto, TrainDto,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

@Component({
  name: "LecturerDetail",
  components: {
    AttachmentsView,
  },
  filters: {
    formatStatus(status?: string) {
      switch (status) {
        case "Draft":
          return "草稿";

        case "Published":
          return "已发布";

        case "Deleted":
          return "已删除";
      }
    },
  },
})
export default class LecturerDetail extends Vue {
  dataId?: number;
  detail: TrainDto = {
    province: {},
    city: {},
    area: {},
    trainChapters: []
  };
  baseURL: any;
  hostType = AttachmentHostType.CmsContentAttachment;
  loading = true;

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  getTime(item: any) {
    return moment(item).format("HH:mm");
  }

  // 获取详情
  async fetchDetail() {
    await api.train.get({id: this.dataId}).then((res) => {
      this.loading = false;
      this.detail = {...res};
    });
  }

  // 下载附件
  downLoad(item: any) {
    window.location.href = `${this.baseURL}/api/services/app/Attachment/Download?id=${item.id}`;
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
